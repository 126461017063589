export class APIError extends Error {
  constructor(
    message: string,
    public code: number,
    public details?: any
  ) {
    super(message);
    this.name = 'APIError';
  }
}

export function handleAPIError(error: any): never {
  if (error.response) {
    throw new APIError(
      error.response.data?.error?.message || 'API request failed',
      error.response.status,
      error.response.data
    );
  }
  throw new Error(error.message || 'Network error occurred');
}