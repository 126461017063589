import Sentiment from 'sentiment';
import type { VideoComment } from '../types/youtube';
import type { AnalysisResult } from '../types/analysis';

const sentiment = new Sentiment();

export function analyzeComments(comments: VideoComment[]): AnalysisResult {
  const sentiments = comments.map(comment => ({
    score: sentiment.analyze(comment.text).score,
    text: comment.text,
    likeCount: comment.likeCount,
  }));

  const positiveComments = sentiments.filter(s => s.score > 0);
  const negativeComments = sentiments.filter(s => s.score < 0);
  
  const overallSentiment = sentiments.reduce((acc, curr) => acc + curr.score, 0) / sentiments.length;
  
  const topPositive = positiveComments
    .sort((a, b) => b.likeCount - a.likeCount)
    .slice(0, 3);
    
  const topNegative = negativeComments
    .sort((a, b) => b.likeCount - a.likeCount)
    .slice(0, 3);

  return {
    overallSentiment,
    positivePercentage: (positiveComments.length / comments.length) * 100,
    totalComments: comments.length,
    topPositiveComments: topPositive,
    topNegativeComments: topNegative,
    recommendation: overallSentiment > 2 ? 'positive' : overallSentiment < -2 ? 'negative' : 'neutral',
  };
}