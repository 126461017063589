import React, { useState, useCallback } from 'react';
import { Sparkles } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useTranslatedAnalysis } from './hooks/useTranslatedAnalysis';
import { LanguageSelector } from './components/LanguageSelector';
import { TemperatureGauge } from './components/TemperatureGauge';
import { AnalysisSummary } from './components/AnalysisSummary';
import { SearchInput } from './components/SearchInput';
import { SearchHistory } from './components/SearchHistory';
import { CookieConsent } from './components/CookieConsent';
import './i18n';

function App() {
  const [query, setQuery] = useState('');
  const { loading, error, results, analyze } = useTranslatedAnalysis();
  const { t } = useTranslation();

  const handleSearch = useCallback(async (searchQuery: string) => {
    if (!searchQuery.trim()) return;
    await analyze(searchQuery);
  }, [analyze]);

  const handleSubmit = useCallback(async (e: React.FormEvent) => {
    e.preventDefault();
    await handleSearch(query);
  }, [query, handleSearch]);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 to-gray-800 text-white pb-16">
      <div className="container mx-auto px-3 py-4 md:px-4 md:py-8">
        <div className="flex justify-end mb-4">
          <LanguageSelector />
        </div>
        
        <div className="max-w-2xl mx-auto">
          <div className="text-center mb-6 md:mb-8">
            <div className="flex items-center justify-center gap-2 mb-2">
              <Sparkles className="w-5 h-5 md:w-6 md:h-6 text-blue-400" />
              <h1 className="text-3xl md:text-4xl font-bold">{t('app.title')}</h1>
            </div>
            <p className="text-gray-300 text-sm md:text-base">
              {t('app.subtitle')}
            </p>
          </div>

          <SearchInput
            value={query}
            onChange={setQuery}
            onSearch={handleSubmit}
            onSearchClick={handleSearch}
            loading={loading}
          />

          {error && (
            <div className="bg-red-500/10 border border-red-500 rounded-lg p-3 md:p-4 mb-6 mt-4">
              <p className="text-red-400 text-sm md:text-base">{error}</p>
            </div>
          )}

          {loading ? (
            <div className="text-center py-6 md:py-8">
              <div className="animate-spin rounded-full h-10 w-10 md:h-12 md:w-12 border-b-2 border-white mx-auto"></div>
              <p className="mt-4 text-gray-300 text-sm md:text-base">{t('app.search.analyzing')}</p>
            </div>
          ) : results ? (
            <div className="bg-gray-800 rounded-lg p-4 md:p-6 shadow-xl mt-6">
              <div className="mb-6">
                <h2 className="text-2xl font-bold mb-4">{t('analysis.title')}</h2>
                
                <TemperatureGauge 
                  sentiment={results.overallSentiment} 
                  positivePercentage={results.positivePercentage} 
                />
                
                <AnalysisSummary results={results} />

                <div className="grid grid-cols-3 gap-4 mb-4">
                  <div className="bg-gray-700 p-4 rounded-lg">
                    <p className="text-sm text-gray-400">{t('analysis.stats.sentiment')}</p>
                    <p className="text-xl font-bold">
                      {results.overallSentiment > 0 ? t('analysis.recommendation.positive') : t('analysis.recommendation.negative')}
                    </p>
                  </div>
                  <div className="bg-gray-700 p-4 rounded-lg">
                    <p className="text-sm text-gray-400">{t('analysis.stats.positiveReviews')}</p>
                    <p className="text-xl font-bold">
                      {results.positivePercentage.toFixed(1)}%
                    </p>
                  </div>
                  <div className="bg-gray-700 p-4 rounded-lg">
                    <p className="text-sm text-gray-400">{t('analysis.stats.totalComments')}</p>
                    <p className="text-xl font-bold">{results.totalComments}</p>
                  </div>
                </div>
              </div>

              <div className="space-y-6">
                <div>
                  <h3 className="text-xl font-semibold mb-3">{t('analysis.comments.positive')}</h3>
                  {results.topPositiveComments.map((comment, index) => (
                    <div key={index} className="bg-gray-700 p-4 rounded-lg mb-2">
                      <p className="text-gray-300">{comment.text}</p>
                      <p className="text-sm text-gray-400 mt-2">
                        {t('analysis.comments.likes', { count: comment.likeCount })}
                      </p>
                    </div>
                  ))}
                </div>

                <div>
                  <h3 className="text-xl font-semibold mb-3">{t('analysis.comments.negative')}</h3>
                  {results.topNegativeComments.map((comment, index) => (
                    <div key={index} className="bg-gray-700 p-4 rounded-lg mb-2">
                      <p className="text-gray-300">{comment.text}</p>
                      <p className="text-sm text-gray-400 mt-2">
                        {t('analysis.comments.likes', { count: comment.likeCount })}
                      </p>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <SearchHistory />
      <CookieConsent />
    </div>
  );
}

export default App;