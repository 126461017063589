import { openDB } from 'idb';

const DB_NAME = 'gulle-guide';
const STORE_NAME = 'search-history';
const RESULTS_STORE = 'search-results';
const MAX_HISTORY_ITEMS = 10;

const dbPromise = openDB(DB_NAME, 1, {
  upgrade(db) {
    if (!db.objectStoreNames.contains(STORE_NAME)) {
      db.createObjectStore(STORE_NAME, { keyPath: 'timestamp' });
    }
    if (!db.objectStoreNames.contains(RESULTS_STORE)) {
      db.createObjectStore(RESULTS_STORE, { keyPath: 'query' });
    }
  },
});

export async function addToSearchHistory(query: string) {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, 'readwrite');
  const store = tx.objectStore(STORE_NAME);
  
  await store.add({
    query,
    timestamp: Date.now(),
  });
  
  // Keep only last 10 searches
  const all = await store.getAll();
  if (all.length > MAX_HISTORY_ITEMS) {
    const toDelete = all.slice(0, all.length - MAX_HISTORY_ITEMS);
    for (const item of toDelete) {
      await store.delete(item.timestamp);
    }
  }
}

export async function getSearchHistory(): Promise<string[]> {
  const db = await dbPromise;
  const tx = db.transaction(STORE_NAME, 'readonly');
  const store = tx.objectStore(STORE_NAME);
  
  const all = await store.getAll();
  return all
    .sort((a, b) => b.timestamp - a.timestamp)
    .map(item => item.query);
}

export async function saveSearchResults(query: string, results: any) {
  const db = await dbPromise;
  const tx = db.transaction(RESULTS_STORE, 'readwrite');
  const store = tx.objectStore(RESULTS_STORE);
  await store.put({
    query,
    results,
    timestamp: Date.now(),
  });
}

export async function getSearchResults(query: string) {
  const db = await dbPromise;
  const tx = db.transaction(RESULTS_STORE, 'readonly');
  const store = tx.objectStore(RESULTS_STORE);
  return await store.get(query);
}