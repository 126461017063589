import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useAnalysis } from './useAnalysis';
import { useSearchHistory } from './useSearchHistory';
import { translateComments } from '../services/translation';
import type { AnalysisResult } from '../types/analysis';

export function useTranslatedAnalysis() {
  const analysis = useAnalysis();
  const { i18n } = useTranslation();
  const { addSearch, loadSearch } = useSearchHistory();
  const [translatedResults, setTranslatedResults] = useState<AnalysisResult | null>(null);

  const analyzeWithTranslation = useCallback(async (searchQuery: string) => {
    // Check cache first
    const cached = await loadSearch(searchQuery);
    if (cached) {
      const translated = {
        ...cached.results,
        topPositiveComments: await translateComments(cached.results.topPositiveComments, i18n.language),
        topNegativeComments: await translateComments(cached.results.topNegativeComments, i18n.language)
      };
      setTranslatedResults(translated);
      return;
    }

    // Perform new analysis
    await analysis.analyze(searchQuery);
    if (analysis.results) {
      const translated = {
        ...analysis.results,
        topPositiveComments: await translateComments(analysis.results.topPositiveComments, i18n.language),
        topNegativeComments: await translateComments(analysis.results.topNegativeComments, i18n.language)
      };
      await addSearch(searchQuery, analysis.results);
      setTranslatedResults(translated);
    }
  }, [i18n.language, analysis, addSearch, loadSearch]);

  return {
    loading: analysis.loading,
    error: analysis.error,
    results: translatedResults,
    analyze: analyzeWithTranslation
  };
}