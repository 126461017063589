import { useState, useCallback } from 'react';
import { searchVideos, getVideoComments } from '../services/api/youtube';
import { analyzeComments } from '../services/analysis';
import { translateComments } from '../services/translation';
import { useSearchHistory } from './useSearchHistory';
import { useTranslation } from 'react-i18next';
import { APIError } from '../utils/error';
import type { AnalysisResult } from '../types/analysis';

export function useMotorcycleAnalysis() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<AnalysisResult | null>(null);
  const { i18n } = useTranslation();
  const { addSearch, loadSearch } = useSearchHistory();

  const analyzeMotorcycle = useCallback(async (searchQuery: string) => {
    if (!searchQuery.trim()) return;
    
    setLoading(true);
    setError(null);

    try {
      // Önce cache'i kontrol et
      const cached = await loadSearch(searchQuery);
      if (cached) {
        const translatedResults = {
          ...cached.results,
          topPositiveComments: await translateComments(cached.results.topPositiveComments, i18n.language),
          topNegativeComments: await translateComments(cached.results.topNegativeComments, i18n.language)
        };
        setResults(translatedResults);
        return;
      }

      // Yeni arama yap
      const videos = await searchVideos(searchQuery);
      
      if (!videos.length) {
        throw new Error('No results found');
      }

      // Her video için yorumları paralel olarak getir
      const commentsPromises = videos.map(video => getVideoComments(video.videoId));
      const allComments = await Promise.allSettled(commentsPromises);
      
      const comments = allComments
        .filter((result): result is PromiseFulfilledResult<any> => result.status === 'fulfilled')
        .map(result => result.value)
        .flat();

      if (!comments.length) {
        throw new Error('No reviews found');
      }

      // Yorumları analiz et
      const analysis = analyzeComments(comments);
      
      // Sonuçları tercüme et
      const translatedAnalysis = {
        ...analysis,
        topPositiveComments: await translateComments(analysis.topPositiveComments, i18n.language),
        topNegativeComments: await translateComments(analysis.topNegativeComments, i18n.language)
      };

      // Orijinal analizi cache'e kaydet
      await addSearch(searchQuery, analysis);
      
      setResults(translatedAnalysis);
    } catch (err) {
      if (err instanceof APIError) {
        setError(`API Error: ${err.message}`);
      } else {
        setError(err instanceof Error ? err.message : 'An unexpected error occurred');
      }
      setResults(null);
    } finally {
      setLoading(false);
    }
  }, [i18n.language, addSearch, loadSearch]);

  return {
    loading,
    error,
    results,
    analyzeMotorcycle
  };
}