import React from 'react';
import { useTranslation } from 'react-i18next';
import { clsx } from 'clsx';
import { ThumbsUp, ThumbsDown, AlertTriangle } from 'lucide-react';

interface TemperatureGaugeProps {
  sentiment: number;
  positivePercentage: number;
}

export function TemperatureGauge({ sentiment, positivePercentage }: TemperatureGaugeProps) {
  const { t } = useTranslation();
  
  const gaugePosition = Math.min(Math.max((positivePercentage + (sentiment * 10)), 0), 100);
  
  const getRecommendationLevel = () => {
    if (gaugePosition >= 70) return 'highly-recommended';
    if (gaugePosition >= 50) return 'recommended';
    if (gaugePosition >= 30) return 'neutral';
    return 'not-recommended';
  };

  const getIcon = () => {
    if (gaugePosition >= 50) return <ThumbsUp className="w-4 h-4" />;
    if (gaugePosition >= 30) return <AlertTriangle className="w-4 h-4" />;
    return <ThumbsDown className="w-4 h-4" />;
  };

  return (
    <div className="mb-6">
      <div className="flex items-center justify-between mb-2">
        <span className="text-sm font-medium text-gray-400">
          {t(`analysis.gauge.${getRecommendationLevel()}`)}
        </span>
        <div className={clsx(
          'flex items-center gap-1.5 px-2 py-1 rounded-full text-xs',
          {
            'bg-emerald-500/20 text-emerald-400': gaugePosition >= 70,
            'bg-green-500/20 text-green-400': gaugePosition >= 50 && gaugePosition < 70,
            'bg-yellow-500/20 text-yellow-400': gaugePosition >= 30 && gaugePosition < 50,
            'bg-red-500/20 text-red-400': gaugePosition < 30,
          }
        )}>
          {getIcon()}
          <span>{Math.round(gaugePosition)}%</span>
        </div>
      </div>
      <div className="relative h-2 bg-gray-700 rounded-full overflow-hidden">
        <div
          className={clsx(
            'absolute h-full transition-all duration-1000 ease-out',
            {
              'bg-emerald-500': gaugePosition >= 70,
              'bg-green-500': gaugePosition >= 50 && gaugePosition < 70,
              'bg-yellow-500': gaugePosition >= 30 && gaugePosition < 50,
              'bg-red-500': gaugePosition < 30,
            }
          )}
          style={{ width: `${gaugePosition}%` }}
        />
      </div>
    </div>
  );
}