import { ref, push, query, orderByChild, limitToLast, onValue, get } from 'firebase/database';
import { database } from './config';
import type { DatabaseReference, Query } from 'firebase/database';

const HISTORY_REF = 'search_history';
const MAX_HISTORY_ITEMS = 10;

export function getHistoryRef(): DatabaseReference {
  return ref(database, HISTORY_REF);
}

export function getRecentSearchesQuery(): Query {
  return query(
    getHistoryRef(),
    orderByChild('timestamp'),
    limitToLast(MAX_HISTORY_ITEMS)
  );
}

export async function pushToHistory(data: { query: string; timestamp: number }) {
  return push(getHistoryRef(), data);
}

export function subscribeToQuery(
  queryRef: Query,
  callback: (data: any) => void
): () => void {
  return onValue(queryRef, callback);
}

export async function getQuerySnapshot(queryRef: Query) {
  return get(queryRef);
}