import { useState } from 'react';
import type { AnalysisResult } from '../types/analysis';
import { analyzeComments } from '../services/analysis';
import { youtube } from '../services/api';
import { handleAPIError } from '../utils/error';

export function useAnalysis() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [results, setResults] = useState<AnalysisResult | null>(null);

  const analyze = async (query: string) => {
    if (!query.trim()) return;
    
    setLoading(true);
    setError(null);

    try {
      const videos = await youtube.searchVideos(query);
      if (!videos.length) {
        throw new Error('No results found');
      }

      const commentsPromises = videos.map(video => youtube.getVideoComments(video.videoId));
      const allComments = await Promise.allSettled(commentsPromises);
      
      const comments = allComments
        .filter((result): result is PromiseFulfilledResult<any> => result.status === 'fulfilled')
        .map(result => result.value)
        .flat();

      if (!comments.length) {
        throw new Error('No reviews found');
      }

      const analysis = analyzeComments(comments);
      setResults(analysis);
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : 'An unexpected error occurred';
      setError(errorMessage);
      setResults(null);
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    error,
    results,
    analyze
  };
}