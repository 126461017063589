export const YOUTUBE_API_KEY = import.meta.env.VITE_YOUTUBE_API_KEY;
export const MAX_RESULTS = 10;
export const CACHE_DURATION = 7 * 24 * 60 * 60 * 1000; // 7 days in milliseconds
export const MIN_COMMENT_LENGTH = 10;

export const ERROR_MESSAGES = {
  API_KEY_MISSING: 'YouTube API key is missing. Please check your environment variables.',
  QUOTA_EXCEEDED: 'API quota exceeded. Please try again later.',
  NO_RESULTS: 'No results found for this search.',
  NETWORK_ERROR: 'Network error occurred. Please check your connection.',
  INVALID_RESPONSE: 'Invalid response from YouTube API.',
};