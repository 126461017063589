import React, { useState, useRef, useEffect } from 'react';
import { Search, Sparkles, Loader2, History } from 'lucide-react';
import { useTranslation } from 'react-i18next';
import { useSearchHistory } from '../hooks/useSearchHistory';

interface SearchInputProps {
  value: string;
  onChange: (value: string) => void;
  onSearch: (e: React.FormEvent) => Promise<void>;
  onSearchClick: (query: string) => Promise<void>;
  loading: boolean;
}

export function SearchInput({ value, onChange, onSearch, onSearchClick, loading }: SearchInputProps) {
  const { t } = useTranslation();
  const { recentSearches } = useSearchHistory();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (suggestionsRef.current && !suggestionsRef.current.contains(event.target as Node)) {
        setShowSuggestions(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSuggestionClick = async (suggestion: string) => {
    onChange(suggestion);
    setShowSuggestions(false);
    await onSearchClick(suggestion);
  };

  return (
    <div className="relative">
      <form onSubmit={onSearch} className="relative">
        <input
          ref={inputRef}
          type="text"
          value={value}
          onChange={(e) => onChange(e.target.value)}
          onFocus={() => setShowSuggestions(true)}
          placeholder={t('app.search.placeholder')}
          className="w-full bg-gray-700 text-white rounded-lg pl-10 pr-12 py-2.5 md:py-3 focus:ring-2 focus:ring-blue-500 focus:outline-none text-sm md:text-base"
          style={{ fontSize: '16px' }} // Prevents zoom on mobile
        />
        <Search className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 w-4 h-4 md:w-5 md:h-5" />
        <button
          type="submit"
          disabled={loading}
          className="absolute right-2 top-1/2 -translate-y-1/2 bg-blue-600 text-white p-1.5 rounded-md hover:bg-blue-700 transition-colors disabled:opacity-50 flex items-center md:px-3 md:py-1.5"
        >
          {loading ? (
            <Loader2 className="w-4 h-4 animate-spin" />
          ) : (
            <>
              <Sparkles className="w-4 h-4 md:mr-1.5" />
              <span className="hidden md:inline text-sm">{t('app.search.button')}</span>
            </>
          )}
        </button>
      </form>

      {showSuggestions && recentSearches.length > 0 && (
        <div
          ref={suggestionsRef}
          className="absolute z-50 top-full left-0 right-0 mt-1 bg-gray-800 border border-gray-700 rounded-lg shadow-lg overflow-hidden"
        >
          <div className="p-2 border-b border-gray-700 flex items-center gap-2">
            <History className="w-4 h-4 text-gray-400" />
            <span className="text-sm text-gray-400">{t('history.recentSearches')}</span>
          </div>
          <div className="max-h-60 overflow-y-auto">
            {recentSearches.map((search, index) => (
              <button
                key={index}
                onClick={() => handleSuggestionClick(search)}
                className="w-full text-left px-4 py-2 text-sm text-gray-300 hover:bg-gray-700/50 focus:bg-gray-700/50 focus:outline-none transition-colors"
              >
                {search}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}