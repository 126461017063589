import { useState, useEffect } from 'react';
import { subscribeToSearchHistory, addSearchToHistory, getRecentSearches } from '../services/firebase/history';
import { saveSearchResults, getSearchResults } from '../utils/searchHistory';
import type { AnalysisResult } from '../types/analysis';

interface CachedSearch {
  query: string;
  results: AnalysisResult;
  timestamp: number;
}

export function useSearchHistory() {
  const [recentSearches, setRecentSearches] = useState<string[]>([]);
  const [cachedResults, setCachedResults] = useState<Map<string, CachedSearch>>(new Map());

  useEffect(() => {
    const unsubscribe = subscribeToSearchHistory((searches) => {
      setRecentSearches(searches);
      // Pre-fetch cached results for all recent searches
      searches.forEach(async (query) => {
        const cached = await getSearchResults(query);
        if (cached) {
          setCachedResults(prev => new Map(prev).set(query, cached));
        }
      });
    });

    // Initial load
    getRecentSearches().then(setRecentSearches);

    return () => unsubscribe();
  }, []);

  const addSearch = async (query: string, results: AnalysisResult) => {
    await Promise.all([
      addSearchToHistory(query),
      saveSearchResults(query, results)
    ]);
    setCachedResults(prev => new Map(prev).set(query, {
      query,
      results,
      timestamp: Date.now()
    }));
  };

  const loadSearch = async (query: string): Promise<CachedSearch | null> => {
    // First check memory cache
    if (cachedResults.has(query)) {
      return cachedResults.get(query) || null;
    }
    
    // Then check IndexedDB
    const cached = await getSearchResults(query);
    if (cached) {
      setCachedResults(prev => new Map(prev).set(query, cached));
      return cached;
    }
    
    return null;
  };

  return {
    recentSearches,
    addSearch,
    loadSearch,
    cachedResults
  };
}