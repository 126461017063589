import { YOUTUBE_API_KEY } from './constants';

// Validate API keys at startup
if (!YOUTUBE_API_KEY) {
  throw new Error('YouTube API key is missing. Please check your environment variables.');
}

export const API_CONFIG = {
  youtube: {
    baseURL: 'https://www.googleapis.com/youtube/v3',
    key: YOUTUBE_API_KEY,
    maxResults: 10,
    defaultParams: {
      regionCode: 'US',
      relevanceLanguage: 'en',
      safeSearch: 'none'
    }
  }
};