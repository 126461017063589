import { DataSnapshot } from 'firebase/database';
import { getRecentSearchesQuery, pushToHistory, subscribeToQuery, getQuerySnapshot } from './database';

export function subscribeToSearchHistory(callback: (searches: string[]) => void) {
  const recentSearchesQuery = getRecentSearchesQuery();
  
  const processSnapshot = (snapshot: DataSnapshot) => {
    const searches: string[] = [];
    snapshot.forEach((childSnapshot) => {
      searches.unshift(childSnapshot.val().query);
    });
    callback(searches);
  };

  return subscribeToQuery(recentSearchesQuery, processSnapshot);
}

export async function addSearchToHistory(query: string) {
  return pushToHistory({
    query,
    timestamp: Date.now()
  });
}

export async function getRecentSearches(): Promise<string[]> {
  const snapshot = await getQuerySnapshot(getRecentSearchesQuery());
  const searches: string[] = [];
  
  snapshot.forEach((childSnapshot) => {
    searches.unshift(childSnapshot.val().query);
  });

  return searches;
}