import React from 'react';
import { useTranslation } from 'react-i18next';
import { TrendingUp, Clock, Loader2 } from 'lucide-react';
import { useSearchHistory } from '../hooks/useSearchHistory';
import { useMotorcycleAnalysis } from '../hooks/useMotorcycleAnalysis';

export function SearchHistory() {
  const { t } = useTranslation();
  const { recentSearches } = useSearchHistory();
  const { analyzeMotorcycle, loading: globalLoading } = useMotorcycleAnalysis();
  const [loadingSearch, setLoadingSearch] = React.useState<string | null>(null);

  const handleSearchClick = async (query: string) => {
    if (loadingSearch || globalLoading) return;
    setLoadingSearch(query);
    try {
      await analyzeMotorcycle(query);
      // Scroll to top with smooth animation
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } finally {
      setLoadingSearch(null);
    }
  };

  if (!recentSearches?.length) return null;

  return (
    <div className="fixed bottom-0 left-0 right-0 bg-gray-800/80 backdrop-blur-md border-t border-gray-700 transition-all duration-300 hover:bg-gray-800/90">
      <div className="container mx-auto px-2 py-2 md:px-4 md:py-3">
        <div className="flex items-center gap-2 md:gap-3 text-sm text-gray-400 overflow-x-auto no-scrollbar">
          <div className="flex items-center gap-1.5 flex-shrink-0 bg-gray-700/50 px-2 py-1 md:px-3 rounded-full">
            <Clock className="w-3 h-3 md:w-4 md:h-4" />
            <span className="text-xs md:text-sm whitespace-nowrap">{t('history.recentSearches')}</span>
          </div>
          <div className="flex items-center gap-1.5 md:gap-2 flex-grow overflow-x-auto no-scrollbar">
            {recentSearches.map((search, index) => (
              <React.Fragment key={search}>
                <button
                  onClick={() => handleSearchClick(search)}
                  disabled={loadingSearch === search || globalLoading}
                  className="bg-gray-700/30 hover:bg-gray-700/50 px-2 py-1 md:px-3 rounded-full text-gray-300 hover:text-white cursor-pointer transition-all duration-200 whitespace-nowrap flex items-center gap-1.5 disabled:opacity-50 text-xs md:text-sm"
                >
                  {loadingSearch === search ? (
                    <Loader2 className="w-3 h-3 animate-spin" />
                  ) : (
                    <TrendingUp className="w-3 h-3" />
                  )}
                  {search}
                </button>
                {index < recentSearches.length - 1 && (
                  <span className="text-gray-600 flex-shrink-0 text-xs">•</span>
                )}
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}