import React from 'react';
import { useTranslation } from 'react-i18next';
import { ThumbsUp, ThumbsDown, AlertCircle } from 'lucide-react';
import type { AnalysisResult } from '../types/analysis';

interface AnalysisSummaryProps {
  results: AnalysisResult;
}

export function AnalysisSummary({ results }: AnalysisSummaryProps) {
  const { t } = useTranslation();
  
  const getSummaryKey = () => {
    if (results.positivePercentage >= 70) return 'very-positive';
    if (results.positivePercentage >= 50) return 'positive';
    if (results.positivePercentage >= 30) return 'neutral';
    return 'negative';
  };

  const getIcon = () => {
    switch (getSummaryKey()) {
      case 'very-positive':
      case 'positive':
        return <ThumbsUp className="w-5 h-5 text-green-400" />;
      case 'neutral':
        return <AlertCircle className="w-5 h-5 text-yellow-400" />;
      default:
        return <ThumbsDown className="w-5 h-5 text-red-400" />;
    }
  };

  return (
    <div className="bg-gray-700/50 rounded-lg p-6 mb-6 backdrop-blur-sm">
      <div className="flex items-start gap-4">
        <div className="bg-gray-800/50 p-3 rounded-full">
          {getIcon()}
        </div>
        <div>
          <h3 className="text-lg font-semibold mb-2">{t('analysis.summary.title')}</h3>
          <p className="text-gray-300">
            {t(`analysis.summary.${getSummaryKey()}`, {
              count: results.totalComments,
              positive: Math.round(results.positivePercentage),
              negative: Math.round(100 - results.positivePercentage)
            })}
          </p>
        </div>
      </div>
    </div>
  );
}