import { useTranslation } from 'react-i18next';
import type { CommentSentiment } from '../../types/analysis';

// Simple mapping of common words and phrases for basic translation
const translations: Record<string, Record<string, string>> = {
  tr: {
    'good': 'iyi',
    'bad': 'kötü',
    'excellent': 'mükemmel',
    'poor': 'zayıf',
    'quality': 'kalite',
    'price': 'fiyat',
    'recommend': 'tavsiye',
    'not': 'değil',
    'very': 'çok',
    'product': 'ürün',
    'service': 'hizmet'
    // Add more common words as needed
  }
  // Add more languages as needed
};

function simpleTranslate(text: string, targetLanguage: string): string {
  if (targetLanguage === 'en') return text;
  
  const languageDict = translations[targetLanguage];
  if (!languageDict) return text;

  return text.split(' ').map(word => {
    const lowerWord = word.toLowerCase();
    return languageDict[lowerWord] || word;
  }).join(' ');
}

export async function translateComments(
  comments: CommentSentiment[], 
  targetLanguage: string
): Promise<CommentSentiment[]> {
  if (targetLanguage === 'en') return comments;

  return comments.map(comment => ({
    ...comment,
    text: simpleTranslate(comment.text, targetLanguage)
  }));
}