import axios from 'axios';
import { API_CONFIG } from '../../config/api';
import { handleAPIError } from '../../utils/error';
import type { VideoComment, Video } from '../../types/youtube';

const youtube = axios.create({
  baseURL: API_CONFIG.youtube.baseURL,
  params: {
    key: API_CONFIG.youtube.key
  },
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
});

export async function searchVideos(query: string): Promise<Video[]> {
  try {
    // Arama sorgusunu optimize et
    const searchQuery = `${query} review experience`;
    
    const response = await youtube.get('/search', {
      params: {
        part: 'id,snippet',
        q: searchQuery,
        type: 'video',
        maxResults: API_CONFIG.youtube.maxResults,
        videoDefinition: 'high',
        relevanceLanguage: 'en',
        order: 'relevance',
        videoDuration: 'medium',
        videoType: 'any'
      }
    });

    if (!response.data?.items?.length) {
      throw new Error('No results found for this search');
    }

    return response.data.items.map((item: any) => ({
      videoId: item.id.videoId,
      title: item.snippet.title,
      description: item.snippet.description
    }));
  } catch (error) {
    return handleAPIError(error);
  }
}

export async function getVideoComments(videoId: string): Promise<VideoComment[]> {
  try {
    const response = await youtube.get('/commentThreads', {
      params: {
        part: 'snippet',
        videoId,
        maxResults: 100,
        order: 'relevance',
        textFormat: 'plainText'
      }
    });

    if (!response.data?.items?.length) {
      return [];
    }

    // Yorumları filtrele ve kaliteli olanları seç
    return response.data.items
      .map((item: any) => ({
        id: item.id,
        text: item.snippet.topLevelComment.snippet.textDisplay,
        likeCount: item.snippet.topLevelComment.snippet.likeCount,
        publishedAt: item.snippet.topLevelComment.snippet.publishedAt
      }))
      .filter(comment => 
        comment.text.length >= 20 && // Minimum yorum uzunluğu
        !comment.text.includes('http') && // Link içermeyen
        comment.likeCount > 0 // En az 1 beğeni alan
      )
      .sort((a, b) => b.likeCount - a.likeCount) // Beğeni sayısına göre sırala
      .slice(0, 50); // En iyi 50 yorumu al
  } catch (error) {
    return handleAPIError(error);
  }
}